$(function () {
  $('form#form-new-recipe button,form#form-edit-recipe button').click(function (evt) {
    var btn = $(this);
    var href = btn.data("href");
    if (typeof(href) !== "undefined") {
      evt.preventDefault();
      btn.closest("form").prop("action", href).submit();
    }
  });
  $('form.form-confirm button.tempt').click(function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    var btn = $(this);
    var form = btn.closest("form");
    var rlly = form.find(".really");
    rlly.removeClass("hidden");
    $(document).one("click", function (evt) {
      console.log("ONCE");
      if (!$(evt.target).is(".really")) {
        rlly.addClass("hidden");
      }
    });
  });
  $('body')
  .on("change", "input.recipe-done", function () {
    var input = $(this);
    var flag = input.is(":checked");
    var recipeId = input.closest("tr").data("recipe");
    $.post("/admin/done.do", {
      "recipe": recipeId,
      "done": flag
    }).fail(function (xhr, status, error) {
      alert(error);
    });
  })
  .on("change", "input.recipe-want", function () {
    var input = $(this);
    var flag = input.is(":checked");
    var recipeId = input.closest("tr").data("recipe");
    $.post("/admin/want.do", {
      "recipe": recipeId,
      "want": flag
    }).fail(function (xhr, status, error) {
      alert(error);
    });
  });
  $("#recipe-table").on("click", "a.recipe-link", function (e) {
    e.preventDefault();
    var tr = $(this).closest("tr");
    recipeView(tr);
  });
function suggestionEngine(options) {
  var suggEngine = new Bloodhound({
    datumTokenizer: function(d) {
      return Bloodhound.tokenizers.whitespace(d);
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: options.script,
      replace: function (url, query) {
        return url.replace("%QUERY", encodeURIComponent(query));
      },
      filter: function (content) {
        return content;
      }
    }
  });
  suggEngine.initialize();
  return suggEngine;
}
$('#input-tags').tokenfield({
  typeahead: [null, { source: suggestionEngine({
    script: "/tag_autocomplete.do?query=%QUERY"
  }).ttAdapter() }]
});

$('#full-view-recipe').on("click", ".close", function () {
  $("body").removeClass("modal-open");
  $('#full-view-recipe').hide().find(".recipe-content").empty();
});

var viewNow = $('#view-recipe');
if (viewNow.length > 0) {
  showFile(viewNow, viewNow);
}

var PIXEL_RATIO = (function () {
  var ctx = document.createElement("canvas").getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
})();


function makeHiDPICanvas(can, w, h, ratio) {
  if (!ratio) { ratio = PIXEL_RATIO; }
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + "px";
  can.style.height = h + "px";
  can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
}

function showFile(info, view) {
  var recipe = info.data("recipe");
  var file = info.data("file");
  var mimeType = info.data("mimetype");

  view.parent().addClass("whirl").addClass("traditional").css("bottom", "0");
  var url;
  if (typeof(recipe) !== "undefined") {
    url = "/viewRecipe.do?recipe=" + recipe;
  } else if (typeof(file) !== "undefined") {
    url ="/viewFile.do?file=" + file;
  }
  if (mimeType === "application/pdf") {
    var $canvas = view.html("<canvas></canvas>").find("canvas");
    var canvas = $canvas.get(0);
    var winWidth = $(window).width();
    var winHeight = $(window).height();
    makeHiDPICanvas(canvas, winWidth, winHeight);
    PDFJS.getDocument(url).then(
      function (pdf) {
        pdf.getPage(1).then(function (page) {
          var viewportS1 = page.getViewport(1.0);
          var docWidth = viewportS1.width;
          var docHeight = viewportS1.height;
          var wScale = winWidth / docWidth;
          var hScale = winHeight / docHeight;
          function fullWidth() {
            var viewport = page.getViewport(wScale);
            makeHiDPICanvas(canvas, viewport.width, viewport.height);
            var context = canvas.getContext('2d');
            page.render({canvasContext: context, viewport: viewport});
            $canvas.one("click", function () {
              fullHeight();
            });
          }
          function fullHeight() {
            var viewport = page.getViewport(hScale);
            makeHiDPICanvas(canvas, viewport.width, viewport.height);
            var context = canvas.getContext('2d');
            page.render({canvasContext: context, viewport: viewport});
            $canvas.one("click", function () {
              fullWidth();
            });
          }
          if (wScale < hScale) {
            fullWidth();
          } else {
            fullHeight();
          }
          view.parent().removeClass("whirl").removeClass("traditional").css("bottom", "");
        });
    });
  } else {
    view.load(url, function () {
      view.parent().removeClass("whirl").removeClass("traditional").css("bottom", "");
      view.find("article").addClass("col-xs-12 col-md-6 text-left");
    });
    
  }
};
function recipeView(info) {
  var view = $('#full-view-recipe').show();
  $("body").addClass("modal-open");
  showFile(info, view.find(".recipe-content"));
}
$('input:enabled:visible:first').focus();
});